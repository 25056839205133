import firebase from 'firebase/app'
import 'firebase/firestore'

export const firebaseConfig = {
  apiKey: 'AIzaSyDCdMVct6sQsR9oaM-7o_PYiIFYGVq3FLQ',
  authDomain: 'pastel-dev-4a234.firebaseapp.com',
  databaseURL: 'https://pastel-dev-4a234.firebaseio.com',
  projectId: 'pastel-dev-4a234',
  storageBucket: 'pastel-dev-4a234.appspot.com',
  messagingSenderId: '1017293414343',
  appId: '1:1017293414343:web:d5b1c3fdc54f3f0d',
}

// Initialize Firebase
export const fb = firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore()
